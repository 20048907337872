import { createTheme } from "@mui/material";
import { forEach, merge } from "lodash";
import { themeColors } from "./ThemeColors";
import themeOptions from "./ThemeOptions";

function createThemes() {
  let themes = {};

  forEach(themeColors, (value, key) => {
    themes[key] = createTheme(merge({}, themeOptions, value));
  });

  return themes;
}

export const themes = createThemes();
