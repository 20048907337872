import { themes } from "../Theme/InitThemes";
import layout1Settings from "./layout1/Layout1Settings";

export const LayoutSettings = {
  activeLayout: "layout1", // layout1, layout2
  activeTheme: "blue", // View all valid theme colors inside Theme/themeColors.js
  perfectScrollbar: false,

  themes: themes,
  layout1Settings,

  // rtl: false,
  rtl: true,

  showSidenav: false,

  secondarySidebar: {
    show: false,
    open: false,
    theme: "slateDark1", // View all valid theme colors inside Theme/themeColors.js
  },
  // Footer options
  footer: {
    show: false,
    fixed: false,
    theme: "slateDark1", // View all valid theme colors inside Theme/themeColors.js
  },
  isAxiosSetup: false,
  triggers: {
    // used to Trigger
    firebase: {
      triggerRefreshToken: false,
      refreshResult: null,
    },
    server: {
      triggerRefreshToken: false,
      refreshResult: null,
    },
    app: {
      triggerLogout: false,
    },
  },
  showGlobalAlert: {
    show: false,
    message: "",
    severity: "success",
  },
};
