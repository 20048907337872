import { useEffect, useState } from "react";
import { ExpandMore, Search } from "@mui/icons-material";
import fetchGithubFile from "../../utils/getList";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Grid,
  styled,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { FlexAlignCenter } from "../../components/FlexBox";
import { Span } from "../../components/Typography";
import { ReactComponent as Wave } from "./Wave.svg";
import { useTranslation } from "react-i18next";
import useSettings from "../../hooks/useSettings";

// styled components
const ContentBox = styled(FlexAlignCenter)(({ theme }) => ({
  flexDirection: "column",
  padding: "68px 16px 100px 16px",
  background: theme.palette.primary.main,
}));

const CardRootTop = styled("div")(({ theme }) => ({
  maxWidth: "650px",
  margin: "0 auto 50px auto",
}));

const CardRoot = styled(Card)(({ theme }) => ({
  marginTop: "-48px",
  padding: "8px 16px",
  marginRight: "2rem",
  marginLeft: "2rem",
  borderRadius: "8px",

  [theme.breakpoints.down("sm")]: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
}));

const StyledAccordionHeading = styled(Span)(({ theme }) => ({
  fontSize: "1rem",
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  // Make it RTL
  "& .MuiAccordionSummary-root": {
    flexDirection: "row-reverse",
    padding: "0 16px",
  },
  "& .MuiAccordionSummary-expandIcon": {
    left: "auto",
    right: 0,
    [theme.breakpoints.up("sm")]: {
      right: "auto",
      left: 0,
    },
  },
  "& .MuiAccordionSummary-content": {
    [theme.breakpoints.up("sm")]: {
      justifyContent: "end",
    },
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    justifyContent: "end",
    textAlign: "right",
  },
}));

const WaveDiv = ({ height = "160px" }) => {
  // Determine scale based on desired height vs. original SVG height
  const scaleFactor = parseInt(height) / 320;

  return (
    <div style={{ position: "relative", height, overflow: "hidden" }}>
      <svg
        viewBox="0 0 1440 320"
        preserveAspectRatio="xMidYMid meet"
        style={{
          position: "absolute",
          bottom: "0px",
          width: "100%",
          height: "100%",
          transform: `scaleY(${scaleFactor})`,
          transformOrigin: "bottom",
        }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <filter id="f1" x="0" y="0" width="150%" height="150%">
            <feOffset result="offOut" in="SourceAlpha" dx="0" dy="2" />
            <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
          </filter>
        </defs>
        <path fill="#34b3c2" d="M0,0 L1440,0 L1440,160 L0,160 Z"></path>
        <path fill="#008cad" d="M0,160 L1440,160 L1440,320 L0,320 Z"></path>
        <path
          fill="none"
          stroke="#007893"
          strokeWidth="2"
          d="M0,96L48,112C96,128,192,160,288,176C384,192,480,192,576,176C672,160,768,128,864,133.3C960,139,1056,181,1152,192C1248,203,1344,181,1392,171.3L1440,160"
          filter="url(#f1)"
        ></path>
      </svg>
    </div>
  );
};

const Home = () => {
  const { t, i18n } = useTranslation();
  const { settings } = useSettings();
  const { rtl } = settings;
  const [tabIndex, setTabIndex] = useState(0);
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const [useList, setUseList] = useState([]);
  const [useFilteredList, setUseFilteredList] = useState([]);
  const [useExpandedList, setUseExpandedList] = useState([]);
  const [isSetExpandedListSet, setisSetExpandedListSet] = useState(false);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    fetchGithubFile(
      "https://raw.githubusercontent.com/4tal/ConnectPortal/main/docs/home.md"
    ).then((res) => {
      console.log("fetchGithubFile | res - ", res);
      if (res && res?.length > 0) {
        setUseList(res);
        setUseFilteredList(res);
      }
    });
  }, []);

  useEffect(() => {
    try {
      if (useList.length > 0 && !isSetExpandedListSet) {
        const tempExpandedList = [];
        useList.forEach((item, ind) => {
          if (item.path && item.path.length > 0) {
            fetchGithubFile(
              `https://raw.githubusercontent.com/4tal/ConnectPortal/main/docs/${item.path}`,
              "marked"
            ).then((res) => {
              tempExpandedList.push({ [item.path]: res.toString() });
            });
          }
        });

        console.log("fetchGithubFile | tempExpandedList - ", tempExpandedList);
        setUseExpandedList(tempExpandedList);
        setisSetExpandedListSet(true);
      }
    } catch (e) {
      console.error("useEffect | e - ", e);
    }
  }, [isSetExpandedListSet, useList]);

  useEffect(() => {
    console.log("useExpandedList: ", useExpandedList);
  }, [useExpandedList]);

  useEffect(() => {
    if (filter.length > 0) {
      const filteredList = useList.filter((item) =>
        item.name.toLowerCase().includes(filter.toLowerCase())
      );
      setUseFilteredList(filteredList);
    }

    if (filter.length === 0) {
      setUseFilteredList(useList);
    }
  }, [filter, useList]);

  const handleTabChange = (e, value) => setTabIndex(value);

  const handleExpansionClick = (ind) => {
    if (ind === expandedIndex) setExpandedIndex(-1);
    else setExpandedIndex(ind);
  };

  const AccordionData = ({ item }) => {
    const exItem = useExpandedList?.find((exItem) => {
      const key = Object.keys(exItem)[0];
      return key === item.path;
    });
    if (!exItem) {
      return <>Loading...</>;
    }

    const key = Object.keys(exItem)[0];
    const htmlContent = exItem[key];

    return (
      <>
        <div
          key={item.path}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </>
    );
  };

  const StyledAccordionDetails = styled(AccordionDetails)(({ theme, rtl }) => ({
    if(rtl) {
      return {
        // Make it RTL
        flexDirection: "row-reverse",
        padding: "0 32px",
        textAlign: "right",
        //Make li elements RTL
        "& li": {
          textAlign: "right",
          direction: "rtl",
        },
      };
    },
  }));

  const StyledTextField = styled(TextField)(({ theme, rtl }) => {
    if (rtl) {
      return {
        // Make it RTL ( Button to the right, search icon on the left, text RTL align to right)
        "& .MuiOutlinedInput-root": {
          direction: "rtl",
          "& fieldset": {
            border: "none",
          },
          "& input": {
            textAlign: "right",
            paddingRight: "8px",
          },
        },
      };
    } else {
      return {};
    }
  });

  return (
    <div>
      {/* <WaveDiv /> */}

      <ContentBox>
        <StyledTextField
          rtl={rtl}
          fullWidth
          variant="outlined"
          sx={{ maxWidth: 600 }}
          placeholder={t("search_initiatives")}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          InputProps={{
            startAdornment: <Search sx={{ mr: 1 }} fontSize="small" />,
            endAdornment: (
              <Button
                variant="contained"
                sx={{ px: "28px" }}
                style={{ color: "#fff" }}
              >
                {t("search")}
              </Button>
            ),
            style: { background: "white" },
          }}
        />
      </ContentBox>

      <CardRootTop>
        <CardRoot>
          <Grid container spacing={3}>
            <Grid item md={12} sm={12}>
              {useFilteredList.map((item, ind) => (
                <StyledAccordion
                  rtl={rtl}
                  key={ind}
                  elevation={0}
                  expanded={expandedIndex === ind}
                  onClick={() => handleExpansionClick(ind)}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <StyledAccordionHeading>{item.name}</StyledAccordionHeading>
                  </AccordionSummary>
                  <StyledAccordionDetails trl={rtl}>
                    <AccordionData item={item} />
                  </StyledAccordionDetails>
                </StyledAccordion>
              ))}
            </Grid>
          </Grid>
        </CardRoot>
      </CardRootTop>
    </div>
  );
};
export default Home;
