import React, { lazy } from "react";

import Redirect from "../auth/Redirect";
// import AuthGuard from "./auth/AuthGuard";
import NotFound from "../views/sessions/NotFound";
import Home from "../views/home/Home";
// import MainLayout from "./components/MainLayout/MainLayout";
// import sessionRoutes from "./views/sessions/SessionRoutes";
import Layout from "../components/layout/Layout";

const routes = [
  /* {
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      { path: "/account", element: <Account /> },
    ],
  },
  ...sessionRoutes, */
  /* { path: "/home", element: <Home /> }, */
  {
    element: <Layout />,
    children: [
      // ...dashboardRoutes,
      { path: "/home", element: <Home /> },
    ],
  },
  { path: "/", element: <Redirect /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
