import React from "react";
import { StyledEngineProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Main from "./app/Main";
import AppTheme from "./app/components/Theme/AppTheme";
import { SettingsProvider } from "./app/contexts/SettingsContext";
import "./App.css";
import "./app/utils/i18n"; // import the config file

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <SettingsProvider>
          <AppTheme>
            <SnackbarProvider
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
              <Main />
            </SnackbarProvider>
          </AppTheme>
        </SettingsProvider>
      </BrowserRouter>
    </StyledEngineProvider>
  );
}

export default App;
