import React, { useEffect } from "react";
import useSettings from "../../hooks/useSettings";
import AppSuspense from "../AppSuspense";
import { AppLayouts } from "./Index";

const MatxLayout = (props) => {
  const { settings } = useSettings();
  const Layout = AppLayouts[settings.activeLayout];

  return (
    <AppSuspense>
      <Layout {...props} />
    </AppSuspense>
  );
};

export default MatxLayout;
