import { marked } from "marked";

const fetchGithubFile = async (mdUrl, type) => {
  const response = await fetch(mdUrl);
  const text = await response.text();

  switch (type) {
    case "marked":
      return parseFileContentMarked(text);
    default:
      return parseFileContent(text);
  }
};

const parseFileContent = (content) => {
  const lines = content.split("\n");

  const findPath = (i) => {
    if (lines[i].startsWith("## ")) {
      const name = lines[i].substring(3).trim(); // Remove '## ' prefix

      // Check for the pattern include_relative_links/... in the subsequent lines
      const pattern = /include_relative\s(.*?\.md)/;

      let j = i;
      let match = null;
      while (j < lines.length && !match) {
        // console.log("parseFileContent | lines[j] - ", lines[j]);
        match = pattern.exec(lines[j]);
        j++;
      }

      if (match) {
        jsonObjects.push({
          name: name,
          path: match[1],
          link: null,
        });
      }
    }
  };

  const jsonObjects = [];

  for (let i = 0; i < lines.length; i++) {
    if (lines[i].startsWith("## ")) {
      const patternLink = /\[(.*?)\]\(<(.*?)>\)/;
      let matchLink = patternLink.exec(lines[i]);

      if (matchLink) {
        jsonObjects.push({
          name: matchLink[1],
          path: null,
          link: matchLink[2],
        });
      } else {
        findPath(i);
      }
    }
  }

  return jsonObjects;
};

const parseFileContentMarked = (content) => {
  const htmlString = marked(content);
  return htmlString;
};

export default fetchGithubFile;
